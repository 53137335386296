import request from '@/utils/request'

/**
 * 英文研究报告-列表
 * @returns
 */
export async function reportList (param) {
    return request({
        url: '/pc/en/api/product/list',
        method: 'get',
        params: param
    });
}
/**
 * 英文研究报告-详情
 * @returns
 */
export async function reportDetail (param) {
    return request({
        url: '/pc/en/api/product/privatePreView',
        method: 'get',
        responseType: 'blob',
        params: param
    });
}
/**
 * 研究报告-分类列表
 * @returns
 */
export async function classifyList (param) {
    return request({
        url: '/pc/api/main/classes',
        method: 'get',
        params: param
    });
}
/**
 * 获取配置信息-备案号、电话、邮箱、logo
 * @returns
 */
export async function initConfig (param) {
    return request({
        url: '/pc/en/api/init',
        method: 'get',
        params: param
    });
}